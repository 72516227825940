@import url(https://fonts.googleapis.com/css?family=Raleway);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #333;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #0074ba;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #ff8052;
}

.table-warningX2 {
  background-color: #f6b26b;
}

.table-dangerX2 {
  background-color: #f4c7c3;
}

.table-editing {
  background-color: #ECECEC;
}

.border{
border:1px solid black
}

.mb-10px {
  margin-bottom: 10px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-40px{
  margin-left: 120px;
}

.overflow-y-scroll{
  overflow-y: scroll;
}

.max-h-100px{
  max-height: 150px;
}

.min-w-80px{
  min-width: 120px
}

.blue-text{
  color: #0074BA;
}

.bg-red {
  border: 1px solid red;
  background-color: red;
}

.text-medium{
  font-size: 15px;
}
.text-big{
  font-size: 18px;
}

.hv-op1{
  opacity: 1;
}

.hv-op1:hover{
opacity: 1;
}

.mr-30px{
  margin-right: 30px;
}

.gap-15 {
  grid-gap: 15px;
  gap: 15px;
}

.small_text{
  font-size: 12px;
}

.smooth_transition {
  transition: all .2s ease-in-out;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

.center {
  margin-top: 10px;
}

.logo {
  height: 20px;
  width: 80px;
  display: block;
}

.image-fluid {
  width: 275px;
  display: block;
  height: auto;
  margin: 60px auto 30px auto;
}

footer {
  background-color: #f8f9fa;
  padding: 5px;
  margin-top: 10px;
  font-size: 12px;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-container .left {
  flex-grow: 1;
  order: 1;
  text-align: left;
  margin-left: auto;
}

.flex-container .right {
  flex-grow: 1;
  order: 2;
  margin: 5px;
}

.relative-footer {
  position: relative;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 420px) {
  .flex-container .right {
    text-align: center;
  }

  .flex-container .left {
    text-align: center;
  }

  .fixed-footer {
    position: relative;
  }
}

@media screen and (max-width: 550px) {
  .flex-container .right {
    text-align: center;
  }

  .flex-container .left {
    text-align: center;
  }
}

@media screen and (min-width: 550px) {
  .image-fluid {
    width: 500px;
    margin: 100px auto 30px auto;
    height: auto;
  }

  .logo {
    height: 30px;
    width: 100px;
    display: block;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
  }

  .flex-container .left {
    text-align: center;
  }
}

@-webkit-keyframes opacity_animate { 
  0% {
    opacity: 0;
  }
  50%{
    opacity:1
  }
  100% {
    opacity: 0
  }
}

@keyframes opacity_animate { 
  0% {
    opacity: 0;
  }
  50%{
    opacity:1
  }
  100% {
    opacity: 0
  }
}

.animate-opacity{
-webkit-animation: opacity_animate 2s infinite;
        animation: opacity_animate 2s infinite;
}

.max-h-500px{
  max-height: 500px;
}
