@import url('https://fonts.googleapis.com/css?family=Raleway');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #333;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #0074ba;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #ff8052;
}
